.Card {
    border-radius: 13px;
    border: unset;
}

.Icon {
    margin: auto 0;
    padding: 5px 10px;
}
.Content {
    padding: 5px 10px;
}
.Content > * {
    margin: 0 0 0 0;
    display: block;
    color: white;
}

.content > br {
    font-size: 8pt;
}
