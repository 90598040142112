.emptySpaceAcciones {
    width: 40px;
    height: 50px;
}

.iconoButton {
    width: 25px;
    height: 25px;
}

.ButtonEstado{
    width: 25px;
    height: 25px;
    background-color: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 5px;
    margin-right: 10px;
    margin-left: 10px;
}

.customButton {
    background-color: white !important;
    border: 8px solid rgb(80, 5, 255) !important;
  }

.ESTADOButton{
    display: inline-flex;
    padding: 15px 20px;
    justify-content: flex-end !important;
    align-items: center;
    gap: 12px;
    border-radius: 6px !important;
    border: 1px solid #0140EF !important;
    background: #FFF !important;
    color: #0140EF !important;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    margin-left: -20px;
  }

  .ButtonImprimir{
    display: inline-flex;
    padding: 15px 20px;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    border-radius: 6px !important;
    border: 1px solid #0140EF !important;
    background: #FFF !important;
    color: #0140EF !important;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    margin-left: 60px;
  }

  .ButtonDelivery :disabled{
    display: inline-flex;
    padding: 15px 20px;
    justify-content: flex-start !important;
    align-items: center;
    gap: 12px;
    border-radius: 6px !important;
    border: 1px solid #9A9A9A !important;
    background: #FFF !important;
    color:#9A9A9A !important;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
  }
  
  .ButtonPedirMoto{
    display: inline-flex;
    padding: 15px 20px;
    justify-content: flex-start !important;
    align-items: center;
    gap: 12px;
    border-radius: 6px !important;
    border: 1px solid #0140EF  !important;
    background: #FFF !important;
    color: #0140EF !important;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    margin-left: 50px;
  }

.ButtonVerPedido{
  display: inline-flex;
  padding: 15px 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  border-radius: 6px !important;
  border: 1px solid  #0140EF !important;
  background: #0140EF !important;
  color: #FFF !important;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-left: 16px;
}

.buttonCopy{
  background: none;
  border: none;
  margin-right: 5px;
  cursor: pointer;
}

.Logo{
  width: 5px;
  height: 10px;
}
  
.modalTitle {
  color: red;
  font-family: 'Inter' !important;
  display: flex;
  justify-content: center;
}
  
.Aviso{
  font-family: 'Inter' !important;
}
  
.cancelarModal{
  color:black
}
  
.botonesContainer {
  display: flex;
  justify-content: space-between;
}

.botonesContainer > * {
  width: 170px; 
}

