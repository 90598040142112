.flexBetween {
    display: flex;
    justify-content: space-between;
}

.colsStyle {
    width: 45%;
}

.width100 {
    width: 100%;
}
