.fieldTime {
    font-size: 2rem !important;
    margin: 13px !important;
}

.checkCircle {
    color: #008000 !important;
    font-size: 2rem !important;
    margin: 13px !important;
}

.reloadIcon {
    color: #ffa500 !important;
    font-size: 2rem !important;
    margin: 13px !important;
}

.sendIcon {
    color: #0000ff;
    font-size: 2rem !important;
    margin: 13px !important;
}

.containerHeader {
    display: flex !important;
    justify-content: flex-end !important;
    margin-bottom: 24px !important;
}

.orderStateRow {
    align-items: center;
}

.cancelButton {
    margin-left: 1rem;
}

.paymentObs {
    display: flex;
    justify-content: space-between;
}

.textSpace {
    padding-right: 0.25rem;
}
