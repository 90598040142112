.TagDelivery {
    color: #9B51E0 !important;
  font-size: 12px;
  font-family: Roboto;
  font-weight: 600;
  background-color: #EDE7F6 !important;
  line-height: 24px;
  border-radius: 27px !important;
  border: 1px solid  #9B51E0 !important;
  margin-left: 8px;
}

.TagPickUp {
    color: #D38B0F !important;
    font-size: 12px;
    font-family: Roboto !important;
    font-weight: 600;
    line-height: 24px;
    border-radius: 27px !important;
    border: 1px solid #D38B0F !important;
    background: rgba(211, 139, 15, 0.10) !important;
    margin-left: 8px;
}

.tagPagoOnline {
    background-color: rgba(241, 81, 31, 0.05) !important;
    color: #f1511f !important;
    border-radius: 8px !important;
    border: 0 !important;
    border: 1px solid #f1511f !important;
    margin-bottom: 0.2rem;
    font-weight: 500;
}

.Pos {
    color: #828282 !important;
    font-size: 14px;
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: 700;
    line-height: 23.52px !important;
}

.Efectivo {
    color:  #828282 !important;
  font-size: 14px;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 700;
  line-height: 23.52px !important;
}

.tagQR {
    color: #454b53 !important;
    border: 1px solid #454b53 !important;
    border-radius: 8px !important;
    background-color: rgba(69, 75, 83, 0.1) !important;
    font-weight: 500;
}

.tagBepsa {
    background-color:rgba(17, 153, 188, 0.1) !important;
    color: rgb(17, 153, 188) !important;
    border-radius: 8px !important;
    border: 0 !important;
    border: 1px solid rgb(17, 153, 188) !important;
    margin-bottom: 0.2rem;
    font-weight: 500;
}

.tagTiendaNaranja {
    color: #fd7202 !important;
    border: 1px solid #fd7202 !important;
    border-radius: 8px !important;
    background-color: rgba(253, 114, 2, 0.05) !important;
    font-weight: 500;
}

.tagBancard {
    color: #454b53 !important;
    border: 1px solid #454b53 !important;
    border-radius: 8px !important;
    background-color: rgba(69, 75, 83, 0.1) !important;
    font-weight: 500;
}

.tagTigoMoney {
    color: #00377d !important;
    border: 1px solid #00377d !important;
    border-radius: 8px !important;
    background-color: #b5c7de !important;
    font-weight: 500;
}
.Subtitle {
    margin-left: 5px;
    font-size: 14px !important; 
    font-weight: 400 !important;
}

.Bancard{
    color: #828282 !important;
    font-size: 14px;
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: 700;
    line-height: 23.52px !important;
    display: flex !important;
    align-items: center !important;
}