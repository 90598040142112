.stateColumnDiv {
    text-transform: 'uppercase';
}

.stateColumnTag {
    border-radius: '5px';
    font-size: '10px';
    padding: '7px';
    align-content: 'center';
    border: 'none';
    margin-right: 0;
}

.externalLinkImg {
 width: 0.8rem;
 height: 0.8rem;
 margin-left: 0.5rem;
}
