.condiciones {
    font-weight: bold;
}

.botonCondiciones {
    padding: 0 !important;
    font-weight: bold;
    height: max-content !important;
}

.botonCondiciones:hover {
    background-color: transparent !important;
}

.botonCondiciones:hover span {
    text-decoration: underline;
}

.modalTitulos {
    font-weight: bold;
}

.tarjetas {
    margin-left: 1rem;
}

.spin {
    display: flex;
    justify-content: center;
    align-items: center;
}
