.cuadradoHeader {
    border: 1px solid;
    border-color: var(--dark-3);
    height: 5rem;
    align-content: center;
    margin: 20px 60px 50px 60px;
    border-radius: 8px;
}

.rangoFechas {
    width: 100%;
}

.tabla {
    margin: 0 60px 50px 60px;
    flex: 1;
}
.botonVolver {
    margin: 0 60px 0 60px;
    justify-content: right;
}
.menuDropdown {
    align-items: center;
    flex-direction: row;
    color: '#4B5563';
}
.verDetalle {
    margin-left: 10px;
}

.marginCodigo {
    margin-left: 10px;
}

.marginBotones {
    margin-top: 25px;
}

.cardResumen {
    display: flex;
    justify-content: space-around;
}

.colsResumen {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.emitidos {
    color: var(--rojo-monchis) !important;
    font-size: 1.6em;
}

.usados {
    color: var(--dark-warning) !important;
    font-size: 1.6em;
}

.gastado {
    color: var(--success) !important;
    font-size: 1.6em;
}

.comision {
    color: var(--info) !important;
    font-size: 1.6em;
}

.dividers {
    height: 6em;
}

.divDetalles {
    text-align: center;
}

.cardReporteCupones {
    padding: 1rem;
}
