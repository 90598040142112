.titulo {
    font-weight: bold;
    font-size: 1.1rem;
}

.cabeceraCard {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.cuadrado {
    width: 20px;
    height: 20px;
    background-color: var(--rojo-monchis);
    border-radius: 5px;
}

.cabeceraCard_cuadrado {
    display: flex;
    justify-content: space-between;
    width: 22%;
}

.restablecerButton {
    margin-right: 0.5rem;
}

.pushPreviewContainer {
    padding: 70px 0;
    display: flex;
    flex-direction: column;
}

.pushPreviewLoader {
    font-size: 40;
    color: #1890ff;
}

.pushPreviewText {
    text-align: center;
    padding-top: 30px;
}

.pushListActionsPadding {
    padding: 1rem;
}

/* PUSH ZONAS */

.pushZonasCollapse {
    width: 100%;
    padding-top: 1rem;
}
