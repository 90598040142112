.marginBottom {
    margin-bottom: 24px;
}

.scrollableBox {
    height: 10rem;
    overflow: 'scroll';
}

.addedProductBox {
    margin-top: 2rem;
}
