.estadoTextoPendiente {
    color: var(--darker) !important;
}

.estadoTextoAprobado {
    color: var(--success) !important;
}

.estadoTextoRechazado {
    color: var(--ant-text-dark) !important;
}
