.card {
    height: 415px !important;
    overflow-y: scroll !important;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.removeButton {
    color: var(--rojo-monchis);
    margin-left: 2rem;
}

.removeButton svg {
    width: 22px;
    height: 22px;
}

.row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 !important;
    flex-wrap: nowrap;
}

.characteristicsRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin: 0 !important;
    flex-wrap: nowrap;
}

.characteristicsFilter {
    width: 24% !important;
    padding-right: 0.5 rem;
}

.checkboxesRow {
    margin: 1rem 0 2rem 0;
}

.checkboxOption {
    display: flex;
    align-items: center !important;
    margin: 1rem 0 2rem 0;
}

.width100 {
    width: 100%;
}

.marginTop30px {
    margin-top: 30px;
}

.characteristicsWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
}

.characteristicsTextWrapper {
    display: flex;
    column-gap: 10px;
}
