ul.anticons-list {
    margin: 10px 0;
    overflow: hidden;
    list-style: none;
    padding-left: 0;
    li {
        position: relative;
        float: left;
        width: 16.66%;
        height: 100px;
        margin: 3px 0;
        padding: 10px 0 0;
        overflow: hidden;
        text-align: center;
        list-style: none;
        background-color: inherit;
        border-radius: 4px;
        cursor: pointer;
        transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

        @include screen-mobile {
            width: 33%;
        }

        .anticon {
            margin: 12px 0 8px;
            font-size: 36px;
            transition: transform 0.3s ease-in-out;
            will-change: transform;
        }

        .anticon-class {
            display: block;
            white-space: nowrap;
            text-align: center;
            transform: scale(0.83);
            .ant-badge {
                transition: color 0.3s ease-in-out;
            }
        }

        &:hover {
            color: $white;
            background-color: $primary;
            .anticon {
                transform: scale(1.4);
            }
            .ant-badge {
                color: $white;
            }
        }

        &.TwoTone:hover {
            background-color: #8ecafe;
        }

        &.copied:hover {
            color: rgba(255, 255, 255, 0.2);
        }

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: $white;
            line-height: 110px;
            text-align: center;
            opacity: 0;
            transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
            content: 'Copied!';
        }

        &.copied::after {
            top: -10px;
            opacity: 1;
        }
    }
}

.copied-code {
    padding: 2px 4px 2px;
    font-size: 12px;
    background: #f5f5f5;
    border-radius: 2px;
}
