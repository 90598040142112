.textAreaPush textarea {
    overflow: hidden !important;
    resize: none !important;
    padding-right: 32px !important;
}

.textAreaSMS textarea {
    overflow: hidden !important;
    resize: none !important;
}

.icon {
    position: absolute !important;
    top: 40px !important;
    right: 10px !important;
    width: 20px !important;
    height: 20px !important;
}

.icon:hover {
    cursor: pointer !important;
}

.info {
    margin-left: 10px;
}

.errorMessage {
    margin: 0 0 0 10px;
    color: var(--error);
}
