.Input{
    border: none !important; 
    outline: none !important;
    border-bottom: 1px solid #ccc !important;
    padding: '8px' !important;
    width: '100%' !important;
}

.customTableHeader {
    background-color: #007bff !important; 
    color: #fff; 
    padding: 8px; 
    
}
.customTableHeader2 th {
    background-color: #F5F5F5 !important; 
    color: #000000; 
    padding: 8px; 

}
  .ant-table-thead .ant-table-cell {
    background-color: green !important;
}
.botonDescargar {
    margin-right: 100px !important;
    margin-left: auto !important;
}

.redcell {
    background-color: red !important;
    color: white !important; 
}

.ant-table-cell.action {
    background-color: red !important;
    color: white;
}
.customTableHeader2 tr:last-child{
    width: 100px !important;
}

.TablaAccionDesmatchear{
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    padding: 0 10px !important;
    width: 100%;
    color: #F11E4C !important;
    cursor: pointer !important;
}

.TablaAccionMatchear{
    display: flex !important;
    align-items: center;
    justify-content: flex-end !important;
    padding: 0 10px;
    width: 100%;
    color: #00C54F; 
    cursor: pointer;
    transition: color 0.3s;
}

.BackgroundTabla1{
    background-color: rgba(241, 30, 76, 0.30) !important;
    cursor: pointer !important;
}

.BackgroundTabla2{
    background-color: rgba(0, 197, 79, 0.10)!important;
    cursor: pointer !important;
}
