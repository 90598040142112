.authContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--rojo-monchis);
    background-repeat: no-repeat;
    background-size: cover;
}

.cardBodyContainer {
    margin: 1rem 0;
    width: calc(100vw * 0.37);
    max-width: 450px;
}

.textCenter {
    text-align: center !important;
}

.textRed {
    color: var(--rojo-monchis);
}
