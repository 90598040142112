.space {
    width: 100% !important;
}

.marginBottom0 {
    margin-bottom: 0;
}

.marginBottom10 {
    margin-bottom: 10px;
}

.justifyFlexEnd {
    display: flex;
    justify-content: flex-end;
}

.alignItemsCenter {
    display: flex;
    align-items: center;
}

.formRow {
    display: flex;
    align-items: flex-end;
    padding: 20px 0;
}

.flex {
    display: flex;
}

.precioFinalBusqueda {
    display: 'flex';
    align-items: 'center';
    flex-direction: 'column';
}
