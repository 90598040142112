.space {
    width: 100%;
}

.rowClass {
    background-color: var(--error-10);
}

.rowClass td {
    background-color: #fff2f2 !important;
}
.rowClass td {
    background-color: #fff2f2 !important;
}

.precioFinal {
    display: 'flex';
    align-items: 'center';
    flex-direction: 'column';
}
.rowClass td:first-child {
    border-left: 1px solid #d71515 !important;
}

.rowClass td:last-child {
    border-right: 1px solid #d71515 !important;
}

.rowClass td {
    border-top: 1px solid #d71515 !important;
    border-bottom: 1px solid #d71515 !important;
}
