.divider {
    margin-top: 0.5rem;
}

.divTitle{
    display:flex;
    flex-direction:row;
    align-items: flex-start;
}

.nombreLocal{
    color: #828282 !important;
    font-size: 14px;
    font-family: 'Roboto' !important;
    line-height: 23.52px;
    font-weight: 400 !important;
}