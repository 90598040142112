.cuadroActualizacion {
    display: flex;
    align-items: center;
    background-color: var(--info-10);
    border: 1px solid var(--info);
    padding: 10px 10px 10px 20px;
    margin-left: 10px;
}

.cuadroActualizacion > button {
    margin-left: 10px;
}

.hora {
    color: var(--dark-3);
    margin-left: 5px;
}

.ButtonActua{
    border: 1px solid  #0140EF !important;
    background: #0140EF !important;
    color: #FFF !important;
    font-family: 'Inter';
}