.space {
    width: 100%;
}

.pendienteReversion {
    color: var(--warning) !important;
    background-color: var(--warning-10) !important;
    border-radius: 8px !important;
    border: 1px solid var(--primary) !important;
}

.pagoReversado {
    color: var(--warning) !important;
    background-color: var(--warning-10) !important;
    border-radius: '8px' !important;
}
