.columnaMostrarDatos {
    padding: 20px 0;
    border: 1px solid #e5e5e5;
    border-radius: 18px;
    display: flex;
    align-items: center;
}

.textoFranquiciasHabilitadas {
    color: #29cdff;
    font-size: 20px;
}

.textoFranquiciasDeshabilitadas {
    color: #f22251;
    font-size: 20px;
}

.textoDrivers {
    color: #f1a11f;
    font-size: 20px;
}

.textoAltasManuales {
    color: #1ba574;
    font-size: 20px;
}

.exclamationIcon {
    margin-right: 15px;
    font-size: 1.5rem;
    color: var(--amarillo);
}

.modalRow {
    flex-flow: row !important;
}
