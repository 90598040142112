.LogoBox {
    display: flex;
    align-items: center;
    height: 64px;
    padding-top: 10px;
    padding-left: 20px;
    background-color: white;
}

.LogoBox p {
    color: black;
    display: inline-block;
    font-size: 1.15em;
    margin-bottom: 0;
}

.Logo {
    height: 40px;
    width: 40px;
    margin-right: 10px;
}

.sideBar {
    -ms-overflow-style: none; /* para Internet Explorer, Edge */
    scrollbar-width: none; /* para Firefox */
    overflow: scroll;
    height: 100vh;
    position: fixed;
    left: 0;
    display: flex;
    flex-direction: column;
}

.sideBar::-webkit-scrollbar {
    display: none; /* para Chrome, Safari, y Opera */
}
