.opacity-1 {
    opacity: 1;
}

.opacity-0 {
    opacity: 0;
}

.opacity-0-1 {
    opacity: 0.1;
}

.opacity-0-2 {
    opacity: 0.2;
}

.opacity-0-3 {
    opacity: 0.3;
}

.opacity-0-4 {
    opacity: 0.4;
}

.opacity-0-5 {
    opacity: 0.5;
}

.opacity-0-6 {
    opacity: 0.7;
}

.opacity-0-7 {
    opacity: 0.7;
}

.opacity-0-8 {
    opacity: 0.8;
}

.opacity-0-9 {
    opacity: 0.9;
}
