.cardTransaccion {
    display: flex !important;
    align-items: center;
}

.cantidadTotal {
    font-size: 20px;
}

.iconoNumeroTotal {
    margin-left: 20px;
    line-height: 1.5rem;
    color: #1f2937;
}

.iconoSub {
    font-size: 15px;
    color: #4b5563;
}

.cardPrincipales {
    width: 22rem;
    border-radius: 0.625rem;
    height: 7rem;
    background: #fff;
    border: 1px solid #d4d4d8;
}

.cardForm {
    background: #fff;
    border-radius: 0.625rem;
    border: 1px solid #d4d4d8;
    padding: 25px;
}

.bloquearCard {
    display: none;
}
