.rowSelectUsarEn {
    margin: 10px 0 20px 0;
}

.selectInput {
    width: 100%;
}

.erroresLista {
    height: 25px;
    margin-top: 20px;
    padding: 0 5px;
    color: var(--rojo-monchis);
}

.condicionesSubtitulos {
    margin-left: 0.5rem;
}
