.buttonContainer {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center !important;
}
.CargarMAS{
    display: inline-flex;
    padding: 15px 20px;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    border-radius: 6px;
    border: 1px solid , #0140EF;
    background: #0140EF !important;
    color: #FFF !important;
    text-align: center !important;
    font-size: 14px;
    font-weight: 600;
    font-family: Inter !important;
}