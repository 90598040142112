.headerDetalle {
    display: flex;
    justify-content: space-around;
}

.box {
    display: flex;
    flex-direction: column;
    width: 220px;
}

.boxDivider {
    height: 150px;
    margin: 0 10px;
}

.boxTotal {
    display: flex;
    justify-content: space-between;
}

.headerEstadoJedi {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
}
