/* Estilos para el componente de ResumenCorte.js */

table.detail-table tbody tr td p {
    margin: 10px 0;
}

.main-container {
    margin: 0 auto !important;
}

.spinner-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
