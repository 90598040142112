.content {
    width: 100%;
    display: flex;
    padding: 20px;
}

.content h3 {
    margin-bottom: 0.5rem;
}

.icon {
    margin-right: 15px;
    font-size: 1.5rem;
    color: var(--amarillo);
}

.body {
    flex-grow: 1;
    margin-top: 2px;
    margin-bottom: 6px;
}

.footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 10px;
}

.footer > div > button {
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 6px;
    font-family: 'Inter';
}

.rowReverse {
    display: flex;
    flex-direction: row-reverse;
}
