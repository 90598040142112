.habilitarProgramacionContainer {
    margin-bottom: 25px;
}

.habilitarProgramacionLabel {
    margin-left: 10px;
    font-weight: bold;
}

.width100 {
    width: 100%;
}

.fileMessage {
    padding: 10px 20px;
}

.checkIcon {
    color: #52c41a;
}

.listIcons {
    color: #000;
    font-weight: bold;
    margin-top: 10;
}

.infoIcon {
    color: #1890ff;
}

.warningIcon {
    color: #faad14;
}

.usersSelectedText {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.usersSelectedList {
    height: 130px;
    overflow: auto;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
}

.removeButton {
    color: var(--rojo-monchis) !important;
}

.listText {
    font-size: 10pt;
}

.infoText {
    padding: 10px 20px;
    font-size: 11;
}
.searchResults {
    height: 130px;
    overflow: auto;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
}

.fullWidth {
    width: 100%;
}

/* VISTA PREVIA  */

.cardVistaPrevia {
    width: 400px;
    border-radius: 10px;
}
