.space {
    width: 100%;
}

.select {
    width: 330px !important;
}

.requiredTick {
    color: red;
}
