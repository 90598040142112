.formItemContainer > div > div > div {
    display: flex;
}
.formItemContainer > div > div > div > span {
    margin-top: 10px;
    margin-right: 10px;
}

.flex1Container {
    flex: 1;
}

.flexContainer {
    display: flex;
}

.width100Container {
    width: 100%;
}

.pointsFormItemContainer {
    margin-top: 26px;
    display: flex;
    align-items: center;
}

.themeFormItemContainer {
    margin-left: 40px;
}

.themeRadioGroupContainer {
    margin-left: 10px;
}

.emptyThemeContainer {
    width: 200px;
    height: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.rewardsFormContainer {
    margin-top: 40px;
}

.rewardsFormListContainer {
    margin-top: 20px;
}

.rewardsSelectContainer {
    width: 100vw;
}

.rewardsRemoveButtonContainer {
    align-items: center;
}

.rewardsRemoveButton {
    color: var(--rojo-monchis);
    font-size: 2rem;
}

.actionsFormItemContainer {
    display: flex;
    justify-content: flex-end;
}

.actionsFormItemContainer > button {
    margin: 0 10px;
}
