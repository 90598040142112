.checkCircleOutlined{
    color: #00C54F;
    font-size: 20px;
    margin-top: 8px;
    cursor: pointer;
}

.closeCircleOutlined{
    color: #F11E4C;
    font-size: 20px;
    margin-top: 8px;
    cursor: pointer;
}
