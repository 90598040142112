.flexJustifyEnd {
    display: flex;
    justify-content: flex-end;
}

.flexJustifyBetween {
    display: flex !important;
    justify-content: space-between !important;
}

.itemFacturaEnBlanco {
    display: flex !important;
    justify-content: space-between !important;
    flex-wrap: nowrap !important;
    padding-top: 20px;
}

.itemDescripcion,
.itemTotal,
.itemIVA {
    margin: 0 1rem !important;
}

.botonEliminar {
    margin-top: 1.7rem;
    font-size: 2rem;
    color: var(--error) !important;
}
