.totalPedidos {
    margin-left: 5px;
    background: rgba(23, 121, 186, 0.1);
}

.notificacionRecargar {
    border: 1px solid #f32351;
    border-radius: 8px;
}

.ant-tabs-tab.ant-tabs-tab-active > div > .ant-btn {
    border-radius: 32px;
    background: rgba(1, 64, 239, 0.10);
}

.Buscador{
    background-color: #fff !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 10px !important;
    border-radius: 10px !important;
    height: 3rem !important;
    padding: 15px 10px !important;
}

.InputBuscador{
    background: none !important;
    border: none !important;
    outline: none !important;
    width: 300px !important;
    height: 100% !important;

}

.Titulo{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;    
}

.Checkbox{
    display: flex !important;
    align-items: center !important;
}

.Check{
    height: 18px !important;
    width: 18px !important;
    cursor: pointer !important;
}

.ColumnaBancard{
    margin-left: 80% !important;
}

.TituloColumna{
    margin-top: 18px !important;
    margin-left: 2px !important;
}
