.divImg {
    display: flex;
    justify-content: center;
}

.flexRowBetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* COMERCIOS - MONTO TOTAL vs. TICKET PROMEDIO */

.titleComercios {
    display: flex;
    justify-content: center;
    padding-bottom: 1.5rem;
}

.cardComercios {
    padding: 20px;
    width: 50vw;
}

.topComercios {
    height: 45rem;
    overflow: auto;
}

/* TOP 15 COMERCIOS - PORCENTAJES */

.titleMayorCompra {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
}

.cardGraficoDona {
    margin-left: 20px;
    padding: 20px;
    width: 40vw;
}

/* DIAS DE MAYOR COMPRA */

.cardDias {
    width: 20vw;
    padding: 20px;
}

.dias {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0;
}

.imgCalendario {
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
}

/* PRODUCTOS MÁS COMPRADOS */

.cardProductosMasComprados {
    padding: 2em 0 0 0;
}

.productosMasComprados {
    display: flex;
    flex-direction: column;
    height: 30rem;
    flex-wrap: wrap;
    padding-left: 3rem;
}

.itemMasComprado {
    width: 32vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* DIRECCIONES */

.cardDirecciones {
    width: 35vw;
    padding: 20px;
}

.topDirecciones {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
}
