.layout_background {
    background-color: #fff;
    height: 100%;
    border-radius: 10px;
    border: solid 1px #d1d5db;
}

.contentCaja {
    padding: 25px;
    justify-content: space-between;
}

.cajaBuscar {
    margin-bottom: 30px;
}

.cardTarjetas {
    width: 100%;
    margin-top: 65px;
    position: relative;
}

.cardTarjeta {
    margin-top: 20px;
    width: 400px;
    border-radius: 10px;
    border: solid 1px #d1d5db;
    background: #f3f4f6;
}

.cabeceraCard {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 10px;
}

.iconoTarjeta {
    margin-right: 10px;
    border-radius: 5px;
    font-size: 13px;
}

.cabeceraCard_cuadrado {
    display: flex;
    justify-content: space-between;
}

.fontSizeTextos {
    font-size: 11px;
}

.fontSizeTextoTipo {
    font-size: 12px;
}

.numerosTarjeta {
    text-align: center;
}

.nroTarjeta {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 10px;
    word-spacing: 40px;
}

.boton {
    text-align: right;
}

.botonRemover {
    margin-top: 5px;
    border-radius: 10px;
}

.datosTarjeta {
    text-align: right;
    justify-content: right;
    margin-top: 20px;
    font-size: 13px;
}
