
 .ant-table-cell::before {
    content: none !important;
}

.ant-table
{
    border-radius: '8px';
    border: '1px solid #BDBDBD';
}

.table-container {
    border-radius: 8px;
    border: 1px solid  #BDBDBD;
    background: #FAFAFA;
    margin-top: 25px;
    }

.table-columns {
    vertical-align: text-bottom;
    padding-bottom: 0;
    height: 2px !important;
}

.addon-child {
    font-weight: 400 !important;
}