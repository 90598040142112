.ant-skeleton-content {
    .ant-skeleton-title {
        background: $skeleton-color;
        border-radius: $skeleton-radius;
    }

    .ant-skeleton-paragraph {
        > li {
            background: $skeleton-color;
            border-radius: $skeleton-radius;
        }
    }
}

.ant-skeleton-header {
    .ant-skeleton-avatar {
        background: $skeleton-color;
    }
}

.ant-skeleton-element {
    .ant-skeleton-button {
        background: $skeleton-color;
        border-radius: $btn-border-radius;
        width: 5.5rem;
        height: $btn-size;
        line-height: $btn-size;

        &.ant-skeleton-button-circle {
            width: $btn-size;
        }
    }

    .ant-skeleton-button-sm {
        width: 4.75rem;
        height: $btn-size-sm;
        line-height: $btn-size-sm;

        &.ant-skeleton-button-circle {
            width: $btn-size-sm;
        }
    }

    .ant-skeleton-button-lg {
        width: 6.25rem;
        height: $btn-size-lg;
        line-height: $btn-size-lg;

        &.ant-skeleton-button-circle {
            width: $btn-size-lg;
        }
    }

    .ant-skeleton-avatar {
        background: $skeleton-color;
        width: $avatar-size;
        height: $avatar-size;
        line-height: $avatar-size;
    }

    .ant-skeleton-avatar-sm {
        width: $avatar-size-sm;
        height: $avatar-size-sm;
        line-height: $avatar-size-sm;
    }

    .ant-skeleton-avatar-lg {
        width: $avatar-size-lg;
        height: $avatar-size-lg;
        line-height: $avatar-size-lg;
    }

    .ant-skeleton-input {
        background: $skeleton-color;
        height: $input-height;
        line-height: $input-height;
        border-radius: $border-radius;
    }

    .ant-skeleton-input-sm {
        height: $input-height-sm;
        line-height: $input-height-sm;
    }

    .ant-skeleton-input-lg {
        height: $input-height-lg;
        line-height: $input-height-lg;
    }
}
