.card {
    width: 100%;
    overflow: hidden;
}

.width100 {
    width: 100%;
}
.characteristicsRow {
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: end;
    margin: 0 !important;
    margin-bottom: 1rem !important;
}

.hourRange {
    width: 11rem;
    margin-bottom: 0 !important;
}

.time {
    width: 100%;
}

.datePicker {
    width: 292px !important;
}

.cardsPromotionTimeWithSidebar {
    width: calc(100vw - 380px);
    flex-flow: nowrap;
    display: flex;
    align-items: flex-start;
    column-gap: 5px;
    overflow-x: scroll;
}

.cardsPromotionTimeWithoutSidebar {
    width: calc(100vw - 250px);
    flex-flow: nowrap;
    display: flex;
    align-items: flex-start;
    column-gap: 5px;
    overflow-x: scroll;
}

.timePicker {
    display: flex;
    column-gap: 5px;
}
