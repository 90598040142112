.icon {
    color: var(--amarillo) !important;
}

.icon svg {
    width: 24px !important;
    height: 24px !important;
}

.title {
    text-transform: uppercase !important;
    text-decoration: underline !important;
}

.textContainer {
    margin-left: 2rem !important;
    margin-top: 1rem !important;
}

.title2 {
    text-decoration: underline !important;
}

.divider {
    margin: 5px 0 !important;
}
