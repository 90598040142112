.rowFormItem {
    margin-bottom: 0;
}

.rowFormItem > div:last-child > div > div > div:first-child {
    display: inline-block;
    width: calc(50% - 8px);
}

.rowFormItem > div:last-child > div > div > div:last-child {
    display: inline-block;
    width: calc(50% - 8px);
    margin: 0 8px;
}

.reporteBalanceChildren {
    margin-left: 1.9rem;
}

.filters {
    margin-right: 10px !important;
}
