.wrapper div.ant-card-body {
    padding-bottom: 0 !important;
}

.dividerContainer,
.divider {
    height: 100% !important;
}

.dragger {
    border: none !important;
    background: none !important;
    background-color: none !important;
}

.checkbox {
    display: flex;
    align-items: center;
}

.item {
    margin-bottom: 0 !important;
    margin-left: 40px !important;
}

.select {
    width: 153px;
}

.requiredTick {
    color: red;
}
