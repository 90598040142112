.actionsButtons {
    display: flex;
    column-gap: 5px;
}

.promName {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
}

.inputPromName {
    width: 100% !important;
}

.space {
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
}
