/* Responsive breakpoints */
$breakpoint-mobile-min: 320px;
$breakpoint-mobile-max: 599px;
$breakpoint-desktop-min: 840px;

/* PADDING */
$fluff-widths: 0, 1, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32,
    34, 36, 40, 42, 44, 48, 50, 52, 54, 56, 60, 64, 68, 72, 76, 80, 86, 90, 94,
    96, 100, 106, 112, 116, 120, 124, 128, 130, 135, 180;

@each $fluff-width in $fluff-widths {
    .pad#{$fluff-width} {
        padding: #{$fluff-width}px;
    }
    .padlr#{$fluff-width},
    .padLR#{$fluff-width} {
        padding-left: #{$fluff-width}px;
        padding-right: #{$fluff-width}px;
    }
    .padl#{$fluff-width},
    .padL#{$fluff-width} {
        padding-left: #{$fluff-width}px;
    }
    .padr#{$fluff-width},
    .padR#{$fluff-width} {
        padding-right: #{$fluff-width}px;
    }
    .padtb#{$fluff-width},
    .padTB#{$fluff-width} {
        padding-top: #{$fluff-width}px;
        padding-bottom: #{$fluff-width}px;
    }
    .padt#{$fluff-width},
    .padT#{$fluff-width} {
        padding-top: #{$fluff-width}px;
    }
    .padB#{$fluff-width},
    .padb#{$fluff-width} {
        padding-bottom: #{$fluff-width}px;
    }
    .marg#{$fluff-width} {
        margin: #{$fluff-width}px;
    }
    .marglr#{$fluff-width},
    .margLR#{$fluff-width} {
        margin-left: #{$fluff-width}px;
        margin-right: #{$fluff-width}px;
    }
    .margl#{$fluff-width},
    .margL#{$fluff-width} {
        margin-left: #{$fluff-width}px;
    }
    .margr#{$fluff-width},
    .margR#{$fluff-width} {
        margin-right: #{$fluff-width}px;
    }
    .margtb#{$fluff-width},
    .margTB#{$fluff-width} {
        margin-top: #{$fluff-width}px;
        margin-bottom: #{$fluff-width}px;
    }
    .mt#{$fluff-width},
    .margt#{$fluff-width},
    .margT#{$fluff-width} {
        margin-top: #{$fluff-width}px;
    }
    .margB#{$fluff-width},
    .margb#{$fluff-width} {
        margin-bottom: #{$fluff-width}px;
    }
    .borderRad#{$fluff-width},
    .borderrad#{$fluff-width} {
        border-radius: #{$fluff-width}px;
    }
}

.margAuto {
    margin: auto;
}
.marglrAuto,
.margLRAuto {
    margin-left: auto;
    margin-right: auto;
}
.marglAuto,
.margLAuto {
    margin-left: auto;
}
.margrAuto,
.margRAuto {
    margin-right: auto;
}
.margtbAuto,
.margTBAuto {
    margin-top: auto;
    margin-bottom: auto;
}
.margtAuto,
.margTAuto {
    margin-top: auto;
}
.margBAuto,
.margbAuto {
    margin-bottom: auto;
}

@media (max-width: $breakpoint-mobile-max) {
    @each $fluff-width in $fluff-widths {
        .m-pad#{$fluff-width} {
            padding: #{$fluff-width}px;
        }
        .m-padlr#{$fluff-width},
        .m-padLR#{$fluff-width} {
            padding-left: #{$fluff-width}px;
            padding-right: #{$fluff-width}px;
        }
        .m-padl#{$fluff-width},
        .m-padL#{$fluff-width} {
            padding-left: #{$fluff-width}px;
        }
        .m-padr#{$fluff-width},
        .m-padR#{$fluff-width} {
            padding-right: #{$fluff-width}px;
        }
        .m-padtb#{$fluff-width},
        .m-padTB#{$fluff-width} {
            padding-top: #{$fluff-width}px;
            padding-bottom: #{$fluff-width}px;
        }
        .m-padt#{$fluff-width},
        .m-padT#{$fluff-width} {
            padding-top: #{$fluff-width}px;
        }
        .m-padB#{$fluff-width},
        .m-padb#{$fluff-width} {
            padding-bottom: #{$fluff-width}px;
        }
        .m-marg#{$fluff-width} {
            margin: #{$fluff-width}px;
        }
        .m-marglr#{$fluff-width},
        .m-margLR#{$fluff-width} {
            margin-left: #{$fluff-width}px;
            margin-right: #{$fluff-width}px;
        }
        .m-margl#{$fluff-width},
        .m-margL#{$fluff-width} {
            margin-left: #{$fluff-width}px;
        }
        .m-margr#{$fluff-width},
        .m-margR#{$fluff-width} {
            margin-right: #{$fluff-width}px;
        }
        .m-margtb#{$fluff-width},
        .m-margTB#{$fluff-width} {
            margin-top: #{$fluff-width}px;
            margin-bottom: #{$fluff-width}px;
        }
        .m-margt#{$fluff-width},
        .m-margT#{$fluff-width} {
            margin-top: #{$fluff-width}px;
        }
        .m-margB#{$fluff-width},
        .m-margb#{$fluff-width} {
            margin-bottom: #{$fluff-width}px;
        }
    }

    .m-margAuto {
        margin: auto;
    }
    .m-marglrAuto,
    .m-margLRAuto {
        margin-left: auto;
        margin-right: auto;
    }
    .m-marglAuto,
    .m-margLAuto {
        margin-left: auto;
    }
    .m-margrAuto,
    .m-margRAuto {
        margin-right: auto;
    }
    .m-margtbAuto,
    .m-margTBAuto {
        margin-top: auto;
        margin-bottom: auto;
    }
    .m-margtAuto,
    .m-margTAuto {
        margin-top: auto;
    }
    .m-margBAuto,
    .m-margbAuto {
        margin-bottom: auto;
    }
}

/** POSITIONING **/
.fclear {
    clear: both;
}

.fright {
    float: right;
}

.fleft {
    float: left;
}

.fcenter {
    float: none;
    margin-left: auto;
    margin-right: auto;
}

.clearfix {
    clear: both;
}

.pos-rel {
    position: relative;
}

.pos-abs {
    position: absolute;
}

.pos-fixed {
    position: fixed;
}

.inblock {
    display: inline-block;
}

.noShrink {
    flex-shrink: 0;
}

.pos-sticky {
    position: -webkit-sticky;
    position: sticky;
}

.FlexContainer,
.flex-container {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;

    &.flex-row {
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap;
    }

    &.flex-row.noWrap {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    &.flex-column {
        flex-flow: column;
    }

    &.mobileCol-desktopRow {
        flex-flow: row;
    }

    &.justify-around {
        justify-content: space-around;
    }

    &.justify-between {
        justify-content: space-between;
    }

    &.justify-evenly {
        justify-content: space-evenly;
    }

    &.justify-center {
        justify-content: center;
    }

    &.justify-end {
        justify-content: flex-end;
    }

    &.justify-start {
        justify-content: flex-start;
    }

    &.text-align-justify {
        text-align: justify;
    }

    &.content-align-between {
        align-content: space-between;
    }

    &.content-align-around {
        align-content: space-around;
    }

    &.content-align-center {
        align-content: center;
    }

    &.content-align-start {
        align-content: flex-start;
    }

    &.content-align-end {
        align-content: flex-end;
    }

    &.items-align-center {
        align-items: center;
    }

    &.items-align-end {
        align-items: flex-end;
    }

    &.align-baseline {
        align-items: baseline;
    }

    > .align-self-center {
        align-self: center;
    }

    > .align-self-end {
        align-self: flex-end;
    }

    > .align-self-start {
        align-self: flex-start;
    }

    @media (max-width: $breakpoint-mobile-max) {
        &.flex-row.mobileWrap {
            flex-wrap: wrap;
        }
        &.mobile-wrap-reverse {
            flex-wrap: wrap-reverse;
        }
        &.mobileCol-desktopRow {
            flex-flow: column;
        }
        &.flex-mobile-col {
            flex-direction: column !important;
        }
        &.flex-mobile-row {
            flex-direction: row !important;
        }
        &.mobile-justify-center {
            justify-content: center;
        }
        &.mobile-justify-end {
            justify-content: flex-end;
        }
        &.mobile-items-align-start {
            align-items: flex-start;
        }
        > .mobile-align-self-initial {
            align-self: initial;
        }
    }
}

/** SIZING **/
.w100 {
    width: 100%;
}
