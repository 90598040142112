.header {
    display: flex;
    background-color: white !important;
    color: black;
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    padding-left: 0 !important;
    align-items: center;
}

.MenuNavBar {
    display: block;
    margin: auto 0px auto auto;
}

.sidebarButton {
    padding: 0.5rem 1.25rem;
    font-size: 18px;
    cursor: pointer;
    transition: color 0.3s;
}

.sidebarButton:hover {
    color: #1890ff;
}
