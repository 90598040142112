.card {
    width: 359px !important;
    height: 415px !important;
    overflow-y: scroll !important;
    top: -2rem;
}

.card div.ant-card-body {
    height: 100% !important;
    display: flex !important;
}

.alignCenter {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
}

.empty {
    display: flex !important;
    flex-direction: column !important;
}

.itemsSelected {
    width: 100%
}
