.textRed {
    color: var(--rojo-monchis);
}

.textCenter {
    text-align: center !important;
}

.formContainer {
    margin: 24px 0;
}
