.userLevelCardContainer {
    padding: 1rem;
}

.scrollableUserLevelListContainer {
    height: 250px;
    overflow: auto;
    padding: 0 16px;
}

.userLevelViewDetailTitle {
    display: flex;
    align-items: center;
}

.userLevelViewDetailTitle > div {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 10px;
}

.userLevelViewDetailTitle > h4 {
    margin-right: 5px;
    line-height: unset;
    margin-bottom: 0;
}

.nivelSubTitulo {
    font-weight: bold;
    text-decoration: underline;
    margin-right: 10px;
}

.listaBeneficios {
    margin: 10px 0;
}

.subTitulo {
    margin-left: 22px;
}
.titulo {
    display: block;
    margin-bottom: 20px;
}
