/* Responsive breakpoints */
$breakpoint-mobile-min: 320px;
$breakpoint-mobile-max: 599px;
$breakpoint-desktop-min: 840px;

/* text-alignment */
.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-justify {
    text-align: justify;
}

.lineHeightNormal {
    line-height: normal;
}

/* Text transforms */
.toUpper {
    text-transform: uppercase;
}
.toTitleCase {
    text-transform: capitalize;
}
.toCapitalCase {
    text-transform: lowercase;
    &:first-letter {
        text-transform: uppercase;
    }
}

.valign-middle {
    vertical-align: middle;
}

.link-style {
    text-decoration: underline;
    cursor: pointer;
}

.no-line {
    text-decoration: none;
}
.text-underline {
    text-decoration: underline;
}

.line-through {
    text-decoration: line-through;
}

.no-pointer {
    cursor: default;
}

.pointer {
    cursor: pointer;
}

/* STD-Text formatting */
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}

@media only screen and (max-width: $breakpoint-mobile-max) {
    .mtext-left,
    .m-text-left {
        text-align: left;
    }
    .mtext-center,
    .m-text-center {
        text-align: center;
        text-align: -moz-center;
        text-align: -webkit-center;
    }
    .mtext-right,
    .m-text-right {
        text-align: right;
    }
}

@media (max-width: $breakpoint-mobile-max) {
    .m-bold {
        font-weight: bold;
    }

    .m-text-justify {
        text-align: justify;
    }
}

.wordbreak-normal {
    word-break: normal;
}

.wordbreak-keep-all {
    word-break: keep-all;
}

.hyphens-none {
    hyphens: none;
}

.whitespace-normal {
    white-space: normal;
}
.whitespace-nowrap {
    white-space: nowrap;
}

.overflow-wrap-normal {
    overflow-wrap: normal;
}

.wordwrap-normal {
    word-wrap: normal;
}

.overflow-hidden {
    overflow: hidden;
}

.ellipsis {
    text-overflow: ellipsis;
}

.bold {
    font-weight: bold;
}
