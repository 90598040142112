@font-face {
  font-family: 'Inter';
  src: url('../../../assets/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url('../../../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}



.botonIntegraciones {
    color: #0d40ca !important;
    border-radius: 6px !important;
    border: 1px solid var(--logistic-admin-dashboard-blue, #0140EF) !important;
    color: 'var(--logistic-admin-dashboard-blue, #0140EF)';
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


.verEstadoJedi {
    color: black;
    font-weight: bold;
    cursor: pointer;
}

.botones{
    color: #0d40ca !important;
    border-radius: 6px !important;
    border: 1px solid var(--logistic-admin-dashboard-blue, #0140EF) !important;
    color: 'var(--logistic-admin-dashboard-blue, #0140EF)';
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.tiempoEstimado {
  color:  #828282 !important;
  text-align: right;
  font-family: Inter !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.52px;
}

.TextNombre{
  color:  #07153D;
  font-size: 0.875rem;
  font-weight: 700;
  font-family: Inter;
  line-Height: 21px;
}

.nombre{
  color:#07153D;
  font-size: 0.875rem;
  font-weight: 700;
  font-family: Inter;
  line-height: 21px;
  margin-right: 5px,
}

.nombrediv{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-items: 'center';
}

.datosContri{
  color: #07153D;
  font-size: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.textMonchisRed {
  color: red;
}

.textMonchisGold {
  color: gold;
}

.textMonchisBlack {
  color: black;
}

