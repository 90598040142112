.PrimaryCustom {
    background-color: var(--verde-primary);
    border-color: var(--verde-primary);
    text-transform: uppercase;
}

.PrimaryCustom:hover,
.PrimaryCustom:focus {
    background-color: var(--verde-primary-hover);
    border-color: var(--verde-primary-hover);
}

.cardTransaccion {
    display: flex !important;
    align-items: center;
}

.cantidadTotal {
    font-size: 20px;
}

.iconoNumeroTotal {
    margin-left: 20px;
    line-height: 1.5rem;
    color: #1f2937;
}

.iconoSub {
    font-size: 15px;
    color: #4b5563;
}

.cardPrincipales {
    width: 22rem;
    border-radius: 0.625rem;
    height: 7rem;
    background: #fff;
    border: 1px solid #d4d4d8;
}

.cardForm {
    background: #fff;
    border-radius: 0.625rem;
    border: 1px solid #d4d4d8;
    padding: 25px;
}

.btnDescargar {
    background: #65a30d;
    color: #fff;
    font-weight: 600;
}

.btnDescargar:hover {
    background: #81b53d;
    color: #fff;
}

.btnDescargaCancelado {
    background: #f43f5e;
    color: #fff;
    font-weight: 600;
}

.btnDescargaCancelado:hover {
    background: #dd5573;
    color: #fff;
}

.btnDescargaTransaccion {
    background: #facc15;
    color: #fff;
    font-weight: 600;
}

.btnDescargaTransaccion:hover {
    background: #eec23f;
    color: #fff;
}

.conjuntoBotones {
    display: flex;
    margin: 20px 0;
}

.btnSeparacion {
    margin: 0 5px;
}

.filters {
    width: 100%;
}
