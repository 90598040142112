.headerDetalle {
    display: flex;
    justify-content:  space-between
}

.rowInfoModal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Inter' !important;
}

.rowInfoModal h5 {
    margin-bottom: 0px !important;
}

.box,.boxResumen {
    display: flex;
    flex-direction: column;
    width: 230px;
}
.boxResumen {
    color:  #828282 !important;
    font-size: 14px !important;
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 23.52px !important;
}

.boxResumenDireccion {
    display: flex;
    flex-direction: column;
    width: 350px;
}

.boxDivider {
    height: 150px;
    margin: 0 10px;
}

.boxTotal {
    display: flex;
    justify-content: space-between;
    margin-top: 2px;
    font-weight: 500;
}

.totalDivider {
    margin: 12px 0;
}

.boxTagContainer {
    margin-top: 10px;
}

.boxTagItem {
    min-width: 100px;
    margin: 10px 0;
}

.verMapa,
.verMapa:focus,
.verMapa:visited {

    color: #0140EF !important;
  font-size: 14px !important;
  font-family: Inter !important;
  font-style: normal;
  cursor: pointer !important;
  font-weight: 700 !important;
  line-height: 23.52px !important;
  text-decoration-line: underline !important;
}

.verMapa:hover {
    color: var(--ant-text-dark);
    text-decoration: underline;
}

.verMapa span svg {
    margin-left: 10px;
    margin-top: -10px;
}

.buttonVerificar {
    margin-top: 0.1rem !important;
    display: inline-flex;
    align-items: center;
    color: red !important;
    font-size: 12px;
    font-family: Inter !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 10px !important;
    margin-left: 2px !important;
    text-decoration: underline;
    cursor: pointer !important;
}

.tiempoPedido {
    margin-top: 0.5rem;
}

.printOrder {
    display: flex;
    align-items: center;
}

.printOrder h5 {
    margin-left: 1rem;
}

.flex {
    display: flex !important;
}

.finalPrice {
    display: flex;
    justify-content: space-between;
}

.FechaOrden {
    color: #828282 !important;
    font-size: 14px;
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 23.52px !important;
}

.Condition1 {
    color: #0140ef !important;
    text-align: center !important;
    font-size: 20px;
    font-family: Inter !important;
    font-style: normal;
    font-weight: 800 !important;
    line-height: normal !important;
}

.DivCondi {
    display: flex !important;
    justify-content: center !important;
    align-content: center !important;
}
.nombreCliente {
    color: #07153d !important;
    font-size: 18px !important;
    font-family: Inter !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 21px !important;
    margin-bottom: 5px !important;
}
.numeroCliente {
    color: #07153d !important;
    font-size: 18px !important;
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 21px !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    letter-spacing: 0.cap !important;
}
.factura {
    color: #828282 !important;
    font-size: 14px !important;
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 23.52px !important;
}
.ruc {
    color: #07153d !important;
    font-size: 18px !important;
    font-family: Inter !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 21px !important;
}

.address {
    color: #07153d !important;
    font-size: 18px !important;
    font-family: Inter !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 21px !important;
    margin-top: 8px;
}

.referencia {
    color: #07153d !important;
    font-size: 18px !important;
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    margin-bottom: 5px !important;
    margin-top: 10px !important;
    letter-spacing: 0.cap !important;
}

.razonSocial {
    color: #07153d !important;
    font-size: 18px !important;
    font-family: Inter !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 21px !important;
}
.direccion {
    color: #828282 !important;
    font-size: 14px !important;
    font-family: Inter !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 23.52px !important;
}
.direccionVerificada {
    display: flex !important;
    align-items: center !important;
    color: #27ae60 !important;
    font-size: 14px;
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 23.52px !important;
    margin-left: 15px !important;
}

.NumberPrice{
color:#1D2021;
text-align: right;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 800;
line-height: 21px;
}

.moneyWithoutBold {
    font-weight: 400;
    color: #1D2021;
}

.payment {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.elapsedTime {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
