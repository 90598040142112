.cards {
    width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 5px;
    margin-top: 10px;
}

.container {
    display: flex;
    justify-content: space-evenly;
}
