.code-box {
    position: relative;
    display: inline-block;
    width: 100%;
    margin: 0 0 map-get($spacers, 3);
    border: 1px solid $border-color;
    border-radius: $border-radius;
    background-color: $white;
}

.code-box-demo {
    padding: 42px 24px 50px;
    border-bottom: 1px solid $border-color;

    iframe {
        border: 0px;
        width: 100%;
    }
}

.code-box-meta {
    &.markdown {
        position: relative;
        width: 100%;
        font-size: $font-size-base;
        border-radius: 0 0 $border-radius $border-radius;
    }
}

.code-box-title {
    position: absolute;
    top: -14px;
    margin-left: 16px;
    padding: 1px 8px;
    color: $body-color;
    background: $white;
    border-radius: $border-radius $border-radius 0 0;
}

.code-box-description {
    padding: map-get($spacers, 3);
}

.code-box-highlight {
    margin-top: map-get($spacers, 3);

    code {
        margin: 0;
        background: transparent;
        padding: 0;
        border-radius: 0;
        font-size: $font-size-base;
        border: 0;
        color: inherit;
    }

    pre {
        margin-bottom: 0px;
    }
}

.code-box-actions {
    margin-top: 12px;
    padding-top: 12px;
    text-align: right;
    border-top: 1px dashed $border-color;
    font-size: 18px;
}

.code-box-icon {
    cursor: pointer;
}

.grid-demo {
    .code-box-demo .ant-row > div:not(.gutter-row):nth-child(2n + 1),
    .code-box-demo .ant-row-flex > div:not(.gutter-row):nth-child(2n + 1) {
        background: rgba(0, 160, 233, 0.7);
    }

    .code-box-demo .ant-row > div:not(.gutter-row),
    .code-box-demo .ant-row-flex > div:not(.gutter-row) {
        padding: 16px 0;
        background: #00a0e9;
    }

    .gutter-box {
        background: #00a0e9;
        padding: 5px 0;
    }

    .code-box-demo .ant-row > div,
    .ant-row-flex > div,
    .code-box-demo .ant-row-flex > div,
    .code-box-demo .ant-row-flex p {
        min-height: 30px;
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 5px 0;
        color: #fff;
        text-align: center;
        border-radius: 0;
    }

    .ant-row-flex,
    .code-box-demo .ant-row-flex {
        background: #f5f5f5;
    }
    .code-box-demo {
        .height-100 {
            height: 100px;
            line-height: 100px;
        }

        .height-120 {
            height: 120px;
            line-height: 120px;
        }
    }

    .grid-demo-playground {
        [class~='ant-col'] {
            background: transparent;
            border: 0;
        }

        [class~='ant-col'] > div {
            height: 100px;
            line-height: 100px;
            font-size: 13px;
        }

        pre {
            background: #f9f9f9;
            border-radius: 6px;
            font-size: 13px;
            margin-bottom: 0px;
            padding: 8px 16px;
        }

        .code-box-demo .ant-row > div:not(.gutter-row):nth-child(2n + 1),
        .code-box-demo .ant-row-flex > div:not(.gutter-row):nth-child(2n + 1),
        .code-box-demo .ant-row > div:not(.gutter-row),
        .code-box-demo .ant-row-flex > div:not(.gutter-row) {
            background-color: transparent;

            > div {
                background: #00a0e9;
            }
        }
    }
}

.code-box-demo-btn {
    .ant-btn {
        margin-right: 8px;
        margin-bottom: 12px;
    }

    .ant-btn-group > .ant-btn,
    .ant-btn-group > span > .ant-btn {
        margin-right: 0;
    }

    .ant-btn-group {
        margin-right: 8px;
    }
}

.code-box-layout-demo {
    .demo-basic {
        .code-box-demo {
            text-align: center;
        }

        .ant-layout-header,
        .ant-layout-footer {
            background: #7dbcea;
            color: $white;
        }

        .ant-layout-footer {
            line-height: 1.5;
        }

        .ant-layout-sider {
            background: #3ba0e9;
            color: #fff;
            line-height: 120px;
        }

        .ant-layout-content {
            background: rgba(16, 142, 233, 1);
            color: #fff;
            min-height: 120px;
            line-height: 120px;
        }

        .code-box-demo > div > .ant-layout {
            margin-bottom: 48px;

            &:last-child {
                margin: 0;
            }
        }
    }

    .demo-custom-trigger,
    .demo-responsive {
        .trigger {
            font-size: 18px;
            line-height: 64px;
            padding: 0 24px;
            cursor: pointer;
            transition: color 0.3s;

            :hover {
                color: $primary;
            }
        }

        .logo {
            height: 32px;
            float: none;
            background: rgba(255, 255, 255, 0.2);
            margin: 16px;
            width: auto;
        }
    }

    .logo {
        width: 120px;
        height: 31px;
        background: rgba(255, 255, 255, 0.2);
        margin: 16px 28px 16px 0;
        float: left;
    }
}

.site-layout .site-layout-background {
    background: #fff;
}

.site-layout-sub-header-background {
    background: #fff;
}

.code-box-affix-demo {
    .scrollable-container {
        height: 100px;
        overflow-y: scroll;
    }
    .background {
        padding-top: 60px;
        height: 300px;
        background-image: url('https://zos.alipayobjects.com/rmsportal/RmjwQiJorKyobvI.jpg');
    }
}

.code-box-breadcrumd-demo {
    .demo {
        margin: 16px;
    }
    .demo-nav {
        height: 30px;
        line-height: 30px;
        margin-bottom: 16px;
        background: #f8f8f8;
    }
    .demo-nav a {
        line-height: 30px;
        padding: 0 8px;
    }
    .app-list {
        margin-top: 16px;
    }
}

.code-box-pagination-demo {
    .ant-pagination:not(:last-child) {
        margin-bottom: 24px;
    }
}

.code-box-pageheader-demo {
    tr:last-child td {
        padding-bottom: 0;
    }
    .ant-statistic-content {
        font-size: 20px;
        line-height: 28px;
    }
    .content {
        display: flex;
    }
    @media (max-width: 576px) {
        .content {
            display: block;
        }

        .main {
            width: 100%;
            margin-bottom: 12px;
        }

        .extra {
            width: 100%;
            margin-left: 0;
            text-align: left;
        }
    }
}

.code-box-datepicker-demo {
    .ant-picker {
        margin: 0 8px 12px 0;
    }
}

.code-box-form-demo {
    .registration,
    .basic,
    .form-method,
    .layout,
    .nest,
    .complex-form-control,
    .control-between-form,
    .time-related-controls,
    .handle-form-data-manually,
    .validate-static,
    .dynamic-rule,
    .other-form {
        .ant-form:not(.ant-form-inline):not(.ant-form-vertical) {
            max-width: 600px;
        }
    }

    .normal-login {
        .login-form {
            max-width: 300px;
        }

        .login-form-forgot {
            float: right;
        }

        .login-form-button {
            width: 100%;
        }
    }

    .advanced-search {
        .ant-advanced-search-form {
            padding: 24px;
            background: #fbfbfb;
            border: 1px solid $border-color;
            border-radius: 6px;

            .ant-form-item {
                display: flex;
            }

            .ant-form-item-control-wrapper {
                flex: 1;
            }
        }

        .ant-form {
            max-width: none;
        }

        .search-result-list {
            margin-top: 16px;
            border: 1px dashed #e9e9e9;
            border-radius: 6px;
            background-color: #fafafa;
            min-height: 200px;
            text-align: center;
            padding-top: 80px;
        }
    }

    .dynamic-form-item {
        .ant-form {
            max-width: 600px;
        }

        .dynamic-delete-button {
            cursor: pointer;
            position: relative;
            top: 4px;
            font-size: 24px;
            color: #999;
            transition: all 0.3s;
        }
        .dynamic-delete-button:hover {
            color: #777;
        }
        .dynamic-delete-button[disabled] {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
}

.code-box-avatar-demo {
    .head-example {
        width: 42px;
        height: 42px;
        border-radius: 4px;
        background: #eee;
        display: inline-block;
        vertical-align: middle;
    }

    .ant-badge:not(.ant-badge-not-a-wrapper) {
        margin-right: 20px;
    }

    .ant-badge-not-a-wrapper:not(.ant-badge-status) {
        margin-right: 8px;
    }
}

.code-box-carousel-demo {
    .ant-carousel .slick-slide {
        text-align: center;
        height: 160px;
        line-height: 160px;
        background: #364d79;
        overflow: hidden;
    }

    .ant-carousel .slick-slide h3 {
        color: #fff;
    }
}

.code-box-calendar-demo {
    .calendar-notice {
        .events {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        .events .ant-badge-status {
            overflow: hidden;
            white-space: nowrap;
            width: 100%;
            text-overflow: ellipsis;
            font-size: 12px;
        }
        .notes-month {
            text-align: center;
            font-size: 28px;
        }
        .notes-month section {
            font-size: 28px;
        }
    }
}

.code-box-list-demo {
    .demo-infinite-container {
        border: 1px solid #e8e8e8;
        border-radius: 4px;
        overflow: auto;
        padding: 8px 24px;
        height: 300px;
    }
    .demo-loading-container {
        position: absolute;
        bottom: 40px;
        width: 100%;
        text-align: center;
    }

    .demo-loading {
        position: absolute;
        bottom: -40px;
        left: 50%;
    }

    .demo-loadmore-list {
        min-height: 350px;
    }
}

.code-box-popover-demo,
.code-box-tooltip-demo,
.code-box-popconfirm-demo {
    .ant-btn {
        margin-right: 8px;
        margin-bottom: 8px;
    }
}

.code-box-tab-cardtop-demo {
    .card-container > .ant-tabs-card > .ant-tabs-content {
        height: 120px;
        margin-top: -16px;
    }

    .card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
        background: #fff;
        padding: 16px;
    }

    .card-container > .ant-tabs-card > .ant-tabs-bar {
        border-color: #fff;
    }

    .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
        border-color: transparent;
        background: transparent;
    }

    .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
        border-color: #fff;
        background: #fff;
    }
}

.code-box-table-demo {
    tr.drop-over-downward td {
        border-bottom: 2px dashed #1890ff;
    }

    tr.drop-over-upward td {
        border-top: 2px dashed #1890ff;
    }

    .react-resizable {
        position: relative;
        background-clip: padding-box;
    }

    .react-resizable-handle {
        position: absolute;
        width: 10px;
        height: 100%;
        bottom: 0;
        right: -5px;
        cursor: col-resize;
        z-index: 1;
    }
}

.code-box-alert-demo {
    .ant-alert {
        margin-bottom: 15px;
    }
}

.code-box-demo-input {
    .example-input > span {
        width: 200px;
        margin: 0 8px 8px 0;
    }
}

.antd-demo-dynamic-option img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.certain-category-search.ant-select-auto-complete
    .ant-input-affix-wrapper
    .ant-input-suffix {
    right: 12px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
    color: #666;
    font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
    border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
    padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
    text-align: center;
    cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
    max-height: 300px;
}

.certain-search-item-count {
    position: absolute;
    color: #999;
    right: 16px;
}

.certain-category-search.ant-select-focused .certain-category-icon {
    color: #108ee9;
}

.certain-category-icon {
    color: #6e6e6e;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-size: 16px;
}
