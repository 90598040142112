.infoBarSection {
  margin: 20px 0;
  display: flex;
  width: 800px;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0px !important;
  border: 2px solid #b6bbb1;
  border-radius: 15px;
}

.infoBarSection > div > h5 {
  margin-bottom: 0 !important;
}

.infoBarSection div {
  display: flex;
  gap: 10px;
}

.infoBarSection>div:first-child>span {
  color: black;
}

.infoBarSection>div:nth-child(2)>span {
  color: red;
}

.infoBarSection>div:nth-child(3)>span {
  color: green;
}

.sincronizarContainer {
  display: flex !important; 
  align-items: center !important;
  cursor: pointer !important;
}