.PrimaryCustom {
    background-color: var(--verde-primary);
    border-color: var(--verde-primary);
    text-transform: uppercase;
    margin: 10px !important;
}

.PrimaryCustom:hover,
.PrimaryCustom:focus {
    background-color: var(--verde-primary-hover);
    border-color: var(--verde-primary-hover);
}

.width100 {
    width: 100%;
}

.balanceChildren {
    margin-left: 1.9rem;
}

.justifyBetween {
    justify-content: space-between;
}

.filters {
    width: 200px;
    margin-right: 10px !important;
}

.cardsRow {
    margin-top: 1rem;
}
