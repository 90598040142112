/* TODO: Si se van a usar estos estilos en todo el admin, moverlos en 'styles.css' para que aplique de manera predeterminada a todos los botones primarios */

.botonPrimario,
.botonPrimario:active {
    background-color: var(--success) !important;
    border-color: var(--success) !important;
    color: var(--light-3) !important;
}

.botonPrimario:hover,
.botonPrimario:focus {
    background-color: var(--success-50) !important;
    border-color: var(--success-50) !important;
    color: var(--light-3) !important;
}

.botonIntegraciones {
    color: #ffffff !important;
    background-color: var(--dark-warning) !important;
}

.integrationButton {
    width: 100% !important;
    margin-bottom: 20px !important;
}
.printOrder {
    display: flex;
    align-items: center;
    
}

.BotonCancelarPedido{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px !important;
    border: 1px solid #D71515 !important;
    color: #D71515 !important;
    font-size: 14px;
    margin-right: '8px' !important;
    font-style: normal;
    font-weight: 600;
    font-family: 'Inter' !important;
    line-height: normal;
    padding: 8px 12px;
}

.BotonEstados{
    display: inline-flex;
  padding: 15px 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  border-radius: 6px !important;
  border: 1px solid  #0140EF !important;
  background-color: #0140EF !important;
  color: white !important;
  text-align: center;
  font-family: 'Inter' !important;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
}

.IntegracionBotones{
    display: flex !important;
    justify-content: space-between !important;
    align-items: self-start !important;
    margin-left: 630px !important;
    margin-top: -40px !important;
}

.Row{
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}