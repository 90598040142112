.buttonAceptar {
    background-color: var(--success);
    color: #fff !important;
    margin-bottom: 10px;
}

.buttonRechazar {
    color: red;
    border-color: red;
}

.containerButtons {
    display: flex;
    flex-direction: column;
}
