.card {
    height: 150px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    padding-top: 25px;
}
.areaTrabajo {
    align-items: center;
    line-height: 22px;
    margin: 0 0 30px 10px;
}

.perfilCenter {
    text-align: center;
}

.botonContinuar {
    justify-content: right;
}

.bordeSeleccionado {
    border: 1px solid #1779ba;
}
