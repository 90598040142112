.menuAcciones {
    margin: 12px 0;
    display: flex;
}

.menuAcciones > button {
    margin: 0 8px !important;
}

.externalLinkImg {
    width: 0.8rem;
    height: 0.8rem;
    margin-left: 0.5rem;
   }
