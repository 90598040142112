:root {
    @font-face {
        font-family: 'Muli-Black';
        src: local('Muli-Black'),
            url(../fonts/Muli-Black.ttf) format('truetype');
    }

    @font-face {
        font-family: 'Muli-Bold';
        src: local('Muli-Bold'), url(../fonts/Muli-Bold.ttf) format('truetype');
    }

    @font-face {
        font-family: 'Muli-ExtraBold';
        src: local('Muli-ExtraBold'),
            url(../fonts/Muli-ExtraBold.ttf) format('truetype');
    }

    @font-face {
        font-family: 'Muli-ExtraLight';
        src: local('Muli-ExtraLight'),
            url(../fonts/Muli-ExtraLight.ttf) format('truetype');
    }

    @font-face {
        font-family: 'Muli-Light';
        src: local('Muli-Light'),
            url(../fonts/Muli-Light.ttf) format('truetype');
    }

    @font-face {
        font-family: 'Muli-Regular';
        src: local('Muli-Regular'),
            url(../fonts/Muli-Regular.ttf) format('truetype');
    }

    font-family: Muli-Regular;

    --rojo-monchis: #f21e4c;
    --amarillo: #ffbe0b;
    --naranja: #fb5607;
    --rosa: #ff006e;
    --morado: #8338ec;
    --azul: #3a86ff;
    --verde-claro: #85be00;
    --verde-primary: #55a630;
    --verde-primary-hover: #86c967;
    /* Nuevos colores: */
    --primary: #f32351;
    --darker: #d0002e;
    --lighter: rgba(243, 35, 81, 0.75);
    --error: #ff3b3b;
    --dark-error: #d33838;
    --error-50: rgba(255, 59, 59, 0.5);
    --error-10: rgba(255, 59, 59, 0.1);
    --warning: #f1a11f;
    --dark-warning: #da9019;
    --warning-50: rgba(241, 161, 31, 0.5);
    --warning-10: rgba(241, 161, 31, 0.1);
    --info: #1779ba;
    --dark-info: #115c8e;
    --info-50: rgba(23, 121, 186, 0.5);
    --info-10: rgba(23, 121, 186, 0.1);
    --success: #1ca676;
    --dark-success: #13845c;
    --success-50: rgba(28, 166, 118, 0.5);
    --success-10: rgba(28, 166, 118, 0.1);
    --dark-1: #333333;
    --dark-2: #7d7d7d;
    --dark-3: #acaaaa;
    --dark-4: #979797;
    --light-1: #f2f2f2;
    --light-2: #fbfbfb;
    --light-3: #ffffff;

    /* Blanco */
    --ant-text-dark: #455560;
}

/* inputs de formularios */
.ant-select-selector,
.ant-input-affix-wrapper {
    border-radius: 10px !important;
}

.ant-layout-sider {
    background: white !important;
}

/* bordes rojos en las tablas */
.tabla-finanzas div div div div table thead.ant-table-thead tr th {
    border-top: 1px solid var(--rojo-monchis);
    border-bottom: 1px solid var(--rojo-monchis);
    background-color: white;
}

.tabla-finanzas div div div div table tbody.ant-table-tbody tr td {
    border-bottom: 1px solid #979797;
}

/* botones */
.PrimaryCustom {
    background-color: var(--verde-primary);
    border-color: var(--verde-primary);
    text-transform: uppercase;
}

.PrimaryCustom:hover,
.PrimaryCustom:focus {
    background-color: var(--verde-primary-hover);
    border-color: var(--verde-primary-hover);
}

.PrimaryOutlinedCustom {
    text-transform: uppercase;
}

.PrimaryOutlinedCustom:hover,
.PrimaryOutlinedCustom:focus {
    color: var(--verde-primary);
    border-color: var(--verde-primary-hover);
}

/* 
 * Ajustes a Tabs de Ant D 
 *
*/

.ant-tabs-nav {
    margin-bottom: 0 !important;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active {
    padding: 16px;
    background: #fff;
}

.ant-tabs-tab-active {
    background: #fff;
    border-color: #fff;
}

/* aqui se utiliza !important por que el antDesign solo acepta los estilos de esta manera 🙄 */
.tag-pago-online {
    background-color: rgba(241, 161, 31, 0.1) !important;
}

.tag-pago-efectivo {
    background-color: rgba(23, 121, 186, 0.1) !important;
}

.tag-pago-pos {
    background-color: rgba(28, 166, 118, 0.1) !important;
}

.text-pago-online {
    color: rgba(241, 161, 31, 1) !important;
    font-size: 15px !important;
}

.text-pago-pos {
    color: rgba(28, 166, 118, 1) !important;
    font-size: 15px !important;
}

.text-pago-efectivo {
    color: rgba(23, 121, 186, 1) !important;
    font-size: 15px !important;
}

.tag-entrega-delivery {
    background-color: rgba(17, 92, 142, 0.25) !important;
}

.tag-entrega-pickup {
    background-color: rgba(241, 161, 31, 0.05) !important;
}

.text-entrega-delivery {
    color: rgba(17, 92, 142, 1) !important;
    font-size: 15px !important;
}

.text-entrega-pickup {
    color: rgba(241, 161, 31, 1) !important;
    font-size: 15px !important;
}

table .ant-checkbox-input {
    opacity: 1 !important;
}

table .ant-checkbox-input,
form .ant-checkbox-inner {
    border: 1px solid #787878 !important;
}

table .ant-checkbox {
    border-width: 10px !important;
    border-color: #787878 !important;
}
