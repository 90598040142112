.content {
    margin: 24px 16px 0;
}

.layout-background {
    background: #fff;
    padding: 20px;
    min-height: 100vh;
    border-radius: 10px;
    border: solid 1px #d1d5db;
}

.cajaInputBoton {
    display: flex;
    justify-content: space-between;
}

.inputBuscar {
    border-radius: 10px;
    width: 160px;
}

.btnAgregar {
    border-radius: 10px;
}

.tablaCupones {
    margin-top: 30px;
}

.ant-table-thead > tr > th {
    font-weight: bold;
}

.dropdownMenu {
    border-radius: 10px;
    padding: 10px;
    color: #d1d5db;
}

/* Dropdown */
.ellipsis-dropdown {
    cursor: pointer;
    font-size: 20px;
    transform: rotate(90deg);
    display: inline-block;
    line-height: 1;
    padding: 0.3125rem;
    border-radius: 50px;
    transition: background-color 0.3s ease;
}

.ellipsis-dropdown:hover {
    background-color: rgba(black, 0.05);
}
/* dropdown end */

.cardSucursalProducto {
    width: 300px;
    border: solid 1px #d1d5db;
    border-radius: 10px;
    margin-right: 20px;
}

.padreCard {
    display: flex;
}

.cardCupones {
    margin-bottom: 20px;
    box-shadow: 0px 2px 7px -1px rgba(143, 141, 143, 1);
}

.contenedorTituloBoton {
    display: flex;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding: 20px;
    border-bottom: solid 1px #d1d5db;
    box-shadow: 0px 2px 7px -1px rgba(143, 141, 143, 1);
    border-radius: 5px;
}

.btnCancelar {
    margin-right: 20px;
    border-radius: 10px;
    color: #6b7280;
}

.labelCupon {
    font-weight: bold;
}
.labelMb {
    margin-bottom: 10px;
}

.expandibleRow {
    height: 50px;
}
