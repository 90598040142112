.rangoFechas {
    width: 100%;
}

.marginBotones {
    margin-top: 25px;
}

.summaryCard {
    width: 50rem;
}

.summary {
    display: flex;
    justify-content: space-around;
}

.colsResumen {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.totalPedidos {
    color: var(--success) !important;
    font-size: 1.6em;
}

.dividers {
    height: 6em;
}

.totalBilling {
    color: var(--info) !important;
    font-size: 1.6em;
}
